"use client";

import { useDebouncedCallback } from "@mantine/hooks";
import { Container } from "@wojo/ui";
import React, { useEffect, useRef, useState } from "react";
import {
    calculateBreadcrumbsTruncation,
    calculateLastBreadcrumbWidth,
} from "./build-breadcrumbs";
import { SchemaScript } from "@wojo/storyblok";
import { BreadcrumbDropdown } from "./BreadcrumbDropdown";
import { BreadcrumbItem } from "./BreadcrumbItem";
import styles from "./Breadcrumbs.module.scss";
import { BreadcrumbsContextProvider } from "./internal";

export type BreadcrumbsProps = {
    items: { link: string; text: string }[];
    /**
     * Inner class name
     */
    innerClassName?: string;
    /**
     * Wrapper class name
     */
    className?: string;
    /**
     * callback function for when a breadcrumb item is clicked
     */
    onBreadcrumbItemClickCustomEvent?: (text: string) => void;
    /**
     * Should use next/link
     */
    withNextLink?: boolean;
};

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
    items,
    innerClassName,
    className,
    onBreadcrumbItemClickCustomEvent,
    withNextLink = false,
}) => {
    const [isDropdown, setIsDropdown] = useState(false);
    const [lastBreadcrumbWidth, setLastBreadcrumbWidth] = useState<
        string | undefined
    >(undefined);
    const [dropdownCount, setDropdownCount] = useState(0);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const breadcrumbsRef = useRef<HTMLOListElement | null>(null);
    const [breadcrumbWidth, setBreadcrumbWidth] = useState(0);
    const [originalBreadcrumbs, setOriginalBreadcrumbs] =
        useState<HTMLElement | null>(null);

    const handleWindowResize = () => {
        const { isDropdown, dropdownCount, breadcrumbWidth } =
            calculateBreadcrumbsTruncation(
                breadcrumbsRef,
                containerRef,
                originalBreadcrumbs,
                items,
            ) ?? {
                isDropdown: false,
                dropdownCount: 0,
                breadcrumbWidth: 0,
            };
        setIsDropdown(isDropdown);
        setDropdownCount(dropdownCount);
        setBreadcrumbWidth(breadcrumbWidth);
    };
    const debouncedHandleResize = useDebouncedCallback(handleWindowResize, 300);

    useEffect(() => {
        if (breadcrumbsRef.current && !originalBreadcrumbs) {
            // Create a deep copy of the breadcrumbs DOM and store it in the state
            setOriginalBreadcrumbs(
                breadcrumbsRef.current.cloneNode(true) as HTMLElement,
            );
        }

        // Check if window is defined
        if (typeof window !== "undefined") {
            window.addEventListener("resize", debouncedHandleResize);
            debouncedHandleResize();
            return () => {
                window.removeEventListener("resize", debouncedHandleResize);
            };
        }
    }, [originalBreadcrumbs, items, debouncedHandleResize]);

    useEffect(() => {
        const width = calculateLastBreadcrumbWidth(
            breadcrumbsRef,
            containerRef,
        );
        setLastBreadcrumbWidth(width ?? undefined);
    }, [breadcrumbWidth, dropdownCount]);

    const breadcrumbData = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        name: "Breadcrumbs",
        itemListElement: items.map((item, index) => {
            const position = index + 1;
            const isLast = position === items.length;
            const url = new URL(item.link, process.env.NEXT_PUBLIC_DOMAIN);

            return {
                "@type": "ListItem",
                position,
                name: item.text,
                item: !isLast ? url : undefined,
            };
        }),
    };

    return (
        <BreadcrumbsContextProvider withNextLink={withNextLink}>
            <Container className={className}>
                <SchemaScript data={breadcrumbData} />
                <nav
                    className={styles.container}
                    ref={containerRef}
                    aria-label="Breadcrumb"
                >
                    <ol className={innerClassName} ref={breadcrumbsRef}>
                        {isDropdown ? (
                            <BreadcrumbDropdown
                                items={items}
                                dropdownCount={dropdownCount}
                                lastBreadcrumbWidth={lastBreadcrumbWidth}
                            />
                        ) : (
                            items.map((breadcrumb, index) => (
                                <BreadcrumbItem
                                    {...breadcrumb}
                                    key={breadcrumb.link}
                                    showSeparator={index < items.length - 1}
                                    isCurrentPage={index === items.length - 1}
                                    lastBreadcrumbWidth={lastBreadcrumbWidth}
                                    onBreadcrumbItemClickCustomEvent={
                                        onBreadcrumbItemClickCustomEvent
                                    }
                                />
                            ))
                        )}
                    </ol>
                </nav>
            </Container>
        </BreadcrumbsContextProvider>
    );
};
