import { createContext, useContext } from "react";

type BreadcrumbsContextValue = {
    withNextLink: boolean;
};

const BreadcrumbsContext = createContext<BreadcrumbsContextValue>(null!);

export type BreadcrumbsProviderProps =
    React.PropsWithChildren<BreadcrumbsContextValue>;

export const BreadcrumbsContextProvider = ({
    children,
    ...rest
}: BreadcrumbsProviderProps) => {
    return (
        <BreadcrumbsContext.Provider value={rest}>
            {children}
        </BreadcrumbsContext.Provider>
    );
};

export const useBreadcrumbsContext = () => {
    return useContext(BreadcrumbsContext);
};
